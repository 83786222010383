import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import "./css/common.css";
import "./css/font.css";
import "./css/layout.css";
import "./css/reset.css";
import "./css/svg.css";
import { addUserInfo, getUserInfo } from "./data/apirequest";
import {
  KAKAO_JS_LIBRATY_URL,
  KAKAO_SCRIPT_ID,
  NAVER_JS_LIBRATY_URL,
  NAVER_SCRIPT_ID,
  USER_TYPE,
} from "./data/defines";
import Loading from "./loading";
import { firebaseLogout, initializeFirebaseLogin } from "./user/firebaseapi";
import { initializeKakaoLogin, kakaoLogout } from "./user/kakaoapi";
import { initializeNaverLogin } from "./user/naverapi";
import { isAndroid, isSystemWebview } from "./utils/deviceChecker";
import { removeSessions, setUserInfoSession } from "./utils/sessions";
import useScript from "./utils/usescript";

const WordbookFragment = lazy(() => import("./home/wordbooklistfragment"));
const LeitnerboxListFragment = lazy(() =>
  import("./leitnerboxlist/leitnerboxlistfragment")
);
const Achievement = lazy(() => import("./achievement/achievement"));
const SettingFragment = lazy(() => import("./setting/settingfragment"));
const LearningFragment = lazy(() => import("./study/learningfragment"));
const MemorizeFragment = lazy(() => import("./study/memorizefragment"));
const QuizFragment = lazy(() => import("./quiz/quizfragment"));
const LoginForm = lazy(() => import("./login/LoginForm"));
const AndroidUpdate = lazy(() => import("./migration/androidUpdate"));
const GuideFragment = lazy(() => import("./guide/guidefragment"));
const IntroFragment = lazy(() => import("./guide/introfragment"));
const PrivacyPolicy = lazy(() => import("./setting/privacypolicy"));

function App() {
  useEffect(() => {
    if (!authenticated && (!isAndroid() || !isSystemWebview()))
      initializeFirebaseLogin(moveResult);
  });

  const [uid, setUid] = useState(null);
  const authenticated = uid != null;

  const logout = (userType, deleteUser = false) => {
    if (isAndroid() && isSystemWebview()) {
      if (deleteUser) window.DIOVOCA_ANDROID.onDeleteUser(userType);
      else window.DIOVOCA_ANDROID.onLogout(userType);
    } else {
      switch (userType) {
        case USER_TYPE.kakao:
          kakaoLogout(() => {
            removeSessions();
            setUid(null);
          });
          break;
        case USER_TYPE.google:
        case USER_TYPE.facebook:
          firebaseLogout(() => {
            removeSessions();
            setUid(null);
          });
          break;
        default:
          removeSessions();
          setUid(null);
          break;
      }
    }
  };
  const moveResult = (loginInfo) => {
    const { id, email, userType } = loginInfo;
    if (!authenticated && id) {
      getUserInfo(id, userType, (response) => {
        if (process.env.NODE_ENV !== "production")
          console.log(
            "moveResult getUserInfo id:" + id + " userType:" + userType
          );
        if (
          (!authenticated && response === null) ||
          response.id === undefined
        ) {
          addUserInfo(id, userType, (response) => {
            setUserInfoSession(id, email, userType);
            setUid(id);
            if (isAndroid() && isSystemWebview())
              window.DIOVOCA_ANDROID.onLogin(id);
          });
        } else {
          setUserInfoSession(id, email, userType);
          setUid(id);
          if (isAndroid() && isSystemWebview())
            window.DIOVOCA_ANDROID.onLogin(id);
        }
      });
    }
  };

  useScript(KAKAO_JS_LIBRATY_URL, KAKAO_SCRIPT_ID, () => {
    if (!authenticated) initializeKakaoLogin(moveResult);
  });
  useScript(NAVER_JS_LIBRATY_URL, NAVER_SCRIPT_ID, () => {
    if (!authenticated) initializeNaverLogin(moveResult);
  });
  if (!authenticated && isAndroid() && isSystemWebview()) {
    var isAuthenticated = window.DIOVOCA_ANDROID.getAuthenticated();
    if (isAuthenticated) {
      var loginInfo = JSON.parse(isAuthenticated);
      moveResult(loginInfo);
    }
  }
  return (
    <>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <AuthRoute
              exact
              path="/"
              render={(props) => <WordbookFragment uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/leitnerboxlist/:id"
              render={(props) => (
                <LeitnerboxListFragment uid={uid} {...props} />
              )}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/achievement/"
              render={(props) => <Achievement uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/setting/"
              render={(props) => (
                <SettingFragment uid={uid} logout={logout} {...props} />
              )}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/learning/:id"
              render={(props) => <LearningFragment uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/memorize/:id"
              render={(props) => <MemorizeFragment uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/quiz/:id/:quizType"
              render={(props) => <QuizFragment uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <AuthRoute
              exact
              path="/mytest/:quizType"
              render={(props) => <QuizFragment uid={uid} {...props} />}
              authenticated={authenticated}
            />
            <Route
              path="/login/"
              render={(props) => (
                <LoginForm
                  authenticated={authenticated}
                  moveResult={moveResult}
                  {...props}
                />
              )}
            />
            <Route path="/guide/" component={GuideFragment} />
            <Route path="/intro/" component={IntroFragment} />
            <AuthRoute
              exact
              path="/migration/"
              render={(props) => (
                <AndroidUpdate
                  uid={uid}
                  authenticated={authenticated}
                  {...props}
                />
              )}
              authenticated={authenticated}
            />
            <Route exact path="/privacy_policy/" component={PrivacyPolicy} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}
export default App;
