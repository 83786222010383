export default function Loading() {
  return (
    <div id="wrap" className="idx_login">
      <div className="login-title">
        <div className="logo_wrap">
          <div className="svg_logo"></div>
        </div>
        <div className="login_description_box">
          <div className="login_description">
            페이지 로딩 중......
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
