let ua = navigator.userAgent.toLowerCase();
let androidSystemWebview = /wv/.test(ua) ? true : false;
let browser = null;

export function getBrowser() {
  if (!browser) {
    if (/(?=.*chrome)^(?!.*edg)^(?!.*android)/.test(ua)) {
      browser = "chrome";
    } else if (/firefox/.test(ua)) {
      browser = "firefox";
    } else if (/edg/.test(ua)) {
      browser = "edge";
    } else if (/trident/.test(ua)) {
      browser = "ie";
    } else if (/android/.test(ua)) {
      browser = "android";
    } else if (/iphone|ipad|ipod/.test(ua)) {
      browser = "ios";
    } else {
      browser = "etc";
    }
  }
  return browser;
}

export function isMobile() {
  return getBrowser() === ("android" || "ios") ? true : false;
}

export function isAndroid() {
  return getBrowser() === "android" ? true : false;
}

export function isiOS() {
  return getBrowser() === "ios" ? true : false;
}

export function isSystemWebview() {
  return androidSystemWebview;
}
