export const WordbookType = {
  CSAT: 0,
  GRADE: 1,
  EBS: 2,
  TOEIC: 3,
};

export const MemorizeState = {
  MEMORIZING: 0,
  NOT_MEMORIZING: 1,
  COMPLETE: 2,
};

export const QuizType = {
  wordQuiz: 0,
  meanQuiz: 1,
  spellcheck: 2,
};

export const ThesaurusType = {
  SYNONYM: 1,
  ANTONYM: 2,
};

export const UNSELECTED_INDEX = -1;

export const LEXILE_GRADE = {
  GRADE_1: 1,
  GRADE_2: 2,
  GRADE_3: 3,
  GRADE_4: 4,
  GRADE_5: 5,
  GRADE_6_TO_8: 6,
  GRADE_9_TO_12: 9,
};
export const LEXILE_GRADE_VALUE = [1, 2, 3, 4, 5, 6, 9];

export const TTS_LOCALE = {
  US_LOCALE: "0",
  UK_LOCALE: "1",
};

export const TTS_SPEECH_RATE = {
  HALF_SPEECH_RATE: "0",
  NORMAL_SPEECH_RATE: "1",
  ONE_AND_HALF_SPEECH_RATE: "2",
  TWICE_SPEECH_RATE: "3",
};

export const USER_TYPE = {
  kakao: "0",
  naver: "1",
  google: "2",
  facebook: "3",
  twitter: "4",
  apple: "5",
};

export const WRONG_ANSWER_REQUEST_TYPE = {
  word: 0,
  mean: 1,
};

export const LEITNERBOX_LEVEL = {
  level_0: 0,
  level_1: 1,
  level_2: 2,
  level_3: 3,
  level_4: 4,
  level_5: 5,
  level_complete: 6,
};

// ms
export const DEFAULT_REMIND_TIME_TO_STRING = {
  level_1: "20분", // 20 minute
  level_2: "1시간", // 1 hour
  level_3: "1일", // 1 day
  level_4: "3일", // 3 days
  level_5: "1주", // 1 week
};

const ONE_MINUTE_TO_MS = 60 * 1000;
const ONE_HOUR_TO_MS = 60 * ONE_MINUTE_TO_MS;
const ONE_DAY_TO_MS = 24 * ONE_HOUR_TO_MS;

// ms
export const DEFAULT_REMIND_MILLISECOND = {
  level_1: 20 * ONE_MINUTE_TO_MS, // 20 minute
  level_2: ONE_HOUR_TO_MS, // 1 hour
  level_3: ONE_DAY_TO_MS, //1 day
  level_4: 3 * ONE_DAY_TO_MS, // 3 days
  level_5: 7 * ONE_DAY_TO_MS, // 1 week
};

export const MEMORIZE_COUNT = {
  COUNT_30: 30,
  COUNT_50: 50,
  COUNT_80: 80,
  COUNT_100: 100,
};

// APP KEY for develope mode
export const NAVER_CLIENT_ID_FOR_LOCALHOST = "5rm4SDMqGhIVon88xoVT";
export const KAKAO_JAVASCRIPT_KEY_FOR_LOCALHOST =
  "b63bfc69831035eb0a29d275b4a35fba";

// APP KEY for production mode
export const NAVER_CLIENT_ID_PRODUCTION = "C5E189g423EUFD3yEof9";
export const KAKAO_JAVASCRIPT_KEY_PRODUCTION =
  "2a46ae61ce45fb97f6aa0c51bb4c29f7";

// APP KEY
export const NAVER_CLIENT_ID =
  process.env.NODE_ENV === "production"
    ? NAVER_CLIENT_ID_PRODUCTION
    : NAVER_CLIENT_ID_FOR_LOCALHOST;
export const KAKAO_JAVASCRIPT_KEY =
  process.env.NODE_ENV === "production"
    ? KAKAO_JAVASCRIPT_KEY_PRODUCTION
    : KAKAO_JAVASCRIPT_KEY_FOR_LOCALHOST;

export const FILEBASE_CONFIG = {
  apiKey: "AIzaSyDd1Cq-VHD2UUZLtLjdGJgo8z2mssHYhp0",
  authDomain: "dio-voca-online.firebaseapp.com",
  projectId: "dio-voca-online",
  storageBucket: "dio-voca-online.appspot.com",
  messagingSenderId: "691491079415",
  appId: "1:691491079415:web:e893991f9a673d374e7ff2",
  measurementId: "G-CHPX6580ZB",
};

export const NAVER_SCRIPT_ID = "naverScript";
export const KAKAO_SCRIPT_ID = "kakaoScript";

export const NAVER_JS_LIBRATY_URL =
  "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js";
export const KAKAO_JS_LIBRATY_URL =
  "https://developers.kakao.com/sdk/js/kakao.js";
