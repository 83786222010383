import { NAVER_CLIENT_ID, USER_TYPE } from "../data/defines";
import { getToken, getUserType, setTokenAndUserType } from "../utils/sessions";
import { getNaverLoginId } from "../data/apirequest";

export const initializeNaverLogin = (moveResult) => {
  if (getUserType() === USER_TYPE.naver) {
    const token = getToken();
    if (token && token.length > 0) {
      getNaverLoginId(token, (data) => {
        console.log("Naver login success");
        const { id, email } = data.response;
        moveResult({ id, email, userType: USER_TYPE.naver });
      });
    }
  }
};

export function showNaverLoginButton(moveResult) {
  const naverLogin = new window.naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: window.location.href,
    callbackHandle: true,
    isPopup: false,
    loginButton: { color: "green", type: 3, height: "40" },
  });
  naverLogin.init();
  naverLogin.logout();
  naverLogin.getLoginStatus((status) => {
    if (status) {
      console.log("Naver login success");
      const params = window.location.href.split("&");
      const access_token =
        params && params.length > 0 ? params[0].split("=")[1] : null;
      setTokenAndUserType(USER_TYPE.naver, access_token);
      const { id, email } = naverLogin.user;
      moveResult({ id, email, userType: USER_TYPE.naver });
    }
  });
}
