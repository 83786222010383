import { KAKAO_JAVASCRIPT_KEY, USER_TYPE } from "../data/defines";
import { getToken, getUserType, setTokenAndUserType } from "../utils/sessions";

export const initializeKakaoLogin = (moveResult) => {
  if (getUserType() === USER_TYPE.kakao) {
    console.log("kakao login");
    if (!window.Kakao.isInitialized || !window.Kakao.isInitialized())
      window.Kakao.init(KAKAO_JAVASCRIPT_KEY);
    const token = getToken();
    if (token && token.length > 0) {
      window.Kakao.Auth.setAccessToken(token);
    }
    console.log("kakao initializeKakaoLogin requestKakaoUserInfo");
    requestKakaoUserInfo(moveResult);
  }
};

export function showkakaoLoginButton(moveResult) {
  if (!window.Kakao.isInitialized || !window.Kakao.isInitialized())
    window.Kakao.init(KAKAO_JAVASCRIPT_KEY);
  window.Kakao.Auth.createLoginButton({
    container: "#kakao-login-button",
    success: (auth) => {
      console.log("KAKAO LOGIN login success");
      const access_token = auth["access_token"];
      if (!window.Kakao.isInitialized || !window.Kakao.isInitialized())
        window.Kakao.init(KAKAO_JAVASCRIPT_KEY);
      requestKakaoUserInfo(moveResult);
      setTokenAndUserType(USER_TYPE.kakao, access_token);
      console.log("KAKAO login success");
    },
    fail: (err) => {
      console.log("KAKAO LOGIN showLoginButton err" + JSON.stringify(err));
    },
  });
}

function requestKakaoUserInfo(moveResult) {
  window.Kakao.API.request({
    url: "/v2/user/me",
    data: {
      property_keys: ["kakao_account.email"],
    },
    success: (res) => {
      console.log("KAKAO LOGIN requestUserInfo success");
      moveResult({
        id: res.id,
        email: res.kakao_account.email,
        userType: USER_TYPE.kakao,
      });
    },
    fail: (err) => {
      console.log("KAKAO LOGIN requestUserInfo err" + JSON.stringify(err));
      kakaoLogout();
    },
  });
}

export function kakaoLogout(callback) {
  window.Kakao.Auth.logout(callback);
}
