import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import { FILEBASE_CONFIG, USER_TYPE } from "../data/defines";
import "firebase/analytics";

export function initializeFirebaseLogin(moveResult) {
  console.log("initializeFirebaseLogin");
  initFirebaseApp();
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      const provideId = user.providerData[0].providerId;
      moveResult({
        id: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        userType:
          provideId === "google.com" ? USER_TYPE.google : USER_TYPE.facebook,
      });
    }
  });
}

function initFirebaseApp() {
  if (!firebase.apps.length) {
    // Configure Firebase.
    firebase.initializeApp(FILEBASE_CONFIG);
  } else {
    firebase.app(); // if already initialized, use that one
  }
}

export default function FirebaseControl() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  initFirebaseApp();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    // signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
  if (!isSignedIn) {
    return (
      <StyledFirebaseAuth
        className="btn-social"
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    );
  } else {
    return (
      <div>
        Welcome {firebase.auth().currentUser.email}! You are now signed-in!
      </div>
    );
  }
}
export function firebaseLogout(callback) {
  initFirebaseApp();
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("firebase logout success");
      callback();
    })
    .catch((error) => {
      console.log("firebase logout fail :" + error);
      callback();
    });
}

export function logAnalyticsEvent(eventName, bundle) {
  initFirebaseApp();
  firebase.analytics().logEvent(eventName, bundle);
}
