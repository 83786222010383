import axios from "axios";
import { USER_TYPE } from "./defines";

/**
 * https://github.com/Selvas-AI/WordBook_Server
 */

const DB_SERVER_URL =
  window.location.protocol === "https:"
    ? "https://voca.selvasai.com:8080"
    : "http://192.168.17.8:8080";

function getPlatform(userType) {
  switch (userType) {
    case USER_TYPE.facebook:
      return "facebook";
    case USER_TYPE.twitter:
      return "twitter";
    case USER_TYPE.google:
      return "google";
    case USER_TYPE.kakao:
      return "kakao";
    case USER_TYPE.naver:
      return "naver";
    case USER_TYPE.apple:
      return "apple";
    default:
      return "unknown";
  }
}

/**********************************************************************************************************/
/** user */
/**
 * 유저 추가
 */
export function addUserInfo(uid, userType, callback) {
  const url =
    DB_SERVER_URL +
    "/user/add?uid=" +
    uid +
    "&platform=" +
    getPlatform(userType);

  if (process.env.NODE_ENV !== "production")
    console.log("addUserInfo url:" + url);
  axios
    .post(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("addUserInfo error:" + err));
}

/**
 * 유저 정보 요청
 */
export function getUserInfo(uid, userType, callback) {
  const url =
    DB_SERVER_URL + "/user?uid=" + uid + "&platform=" + getPlatform(userType);

  if (process.env.NODE_ENV !== "production")
    console.log("getUserInfo url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch((err) => console.log("getUserInfo error:" + err));
}

/**
 * 유저 정보 삭제
 * 등록된 유저중 Parameter로 넘겨받은 UID와 일치하는 유저의 정보를 삭제한다.
 *
 * LeitnerBox, Memorize, Quiz, WordbookState 등 해당 유저와 관련된 모든 정보를 삭제한다.
 * 유저의 UID등의 정보는 유지
 */
export function clearUserInfo(uid, callback) {
  const url = DB_SERVER_URL + "/user/clear?uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("clearUserInfo url:" + url);
  axios
    .delete(url)
    .then((response) => {
      // 유저 정보 삭제에 성공하면 true, 실패하면 false를 넘겨준다.
      if (callback) callback(response.data);
    })
    .catch((err) => console.log("clearUserInfo error:" + err));
}

/**
 * 유저 삭제
 * 등록된 유저중 Parameter로 넘겨받은 UID와 일치하는 유저의 정보를 삭제한다.
 *
 * LeitnerBox, Memorize, Quiz, WordbookState 등 해당 유저와 관련된 모든 정보를 삭제한다
 */
export function deleteUserInfo(uid, callback) {
  const url = DB_SERVER_URL + "/user/delete?uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("deleteUserInfo url:" + url);
  axios
    .delete(url)
    .then((response) => {
      // 유저 정보 삭제에 성공하면 true, 실패하면 false를 넘겨준다.
      if (callback) callback(response.data);
    })
    .catch((err) => console.log("deleteUserInfo error:" + err));
}

/**********************************************************************************************************/
/** Wordbook */

/**
 * Wordbook 목록 요청
 */
export function getWordbookInfos(uid, callback) {
  const url = DB_SERVER_URL + "/wordbook/list?uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getWordbookInfos url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getWordbookInfos error:" + err));
}

/**
 * WordbookInfo 요청 (by WordbookType and SubType)
 */
export function getWordbookInfo(uid, wordbookType, subType, callback) {
  const url =
    DB_SERVER_URL +
    "/wordbook?uid=" +
    uid +
    "&wordbookType=" +
    wordbookType +
    "&subType=" +
    subType;

  if (process.env.NODE_ENV !== "production")
    console.log("getWordbookInfo url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getWordbookInfo error:" + err));
}

/**
 * WordbookInfo 요청 (by wordbookId)
 */
export function getWordbookInfoByWordbookId(uid, wordbookId, callback) {
  const url =
    DB_SERVER_URL + "/wordbook/byId?uid=" + uid + "&wordbookId=" + wordbookId;

  if (process.env.NODE_ENV !== "production")
    console.log("getWordbookInfo url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getWordbookInfo error:" + err));
}

/**
 * 내 실력 확인하기에서 사용할 단어 목록 요청
 */
export function getRecommendWords(callback) {
  const url = DB_SERVER_URL + "/wordbook/recommend";

  if (process.env.NODE_ENV !== "production")
    console.log("getRecommendWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getRecommendWords error:" + err));
}

/**
 * 관련어 목록 요청 (by Word ID and Part)
 *
 * 특정 단어의 관련어의 정보를 요청하는 API이다.
 */
export function getRelativeWords(wordId, part, callback) {
  const url =
    DB_SERVER_URL + "/wordbook/related?wordId=" + wordId + "&part=" + part;

  if (process.env.NODE_ENV !== "production")
    console.log("getRelativeWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getRelativeWords error:" + err));
}

/**
 * Wordbook State 업데이트
 * updateDate, memorizeCount, memorizeState를 갱신한다.
 *
 * updateDate는 현재 Timestamp로 갱신한다.
 * memorizeCount는 memorizeCount에 해당 WordbookState와 매칭되는 LeitnerBox의 count를 더하여 갱신한다.
 * memorizeState는 memorizeCount와 해당 WordbookState와 매칭되는 Wordbook의 wordCount가 같아지면 암기 완료 상태로 변경한다.
 */
export function updateWordbookMemorizeState(uid, leitnerBoxId, callback) {
  const url =
    DB_SERVER_URL +
    "/wordbook/state/update?uid=" +
    uid +
    "&leitnerBoxId=" +
    leitnerBoxId;

  if (process.env.NODE_ENV !== "production")
    console.log("updateWordbookMemorizeState url:" + url);
  axios
    .post(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("updateWordbookMemorizeState error:" + err));
}

/**
 * Wordbook State의 updateDate 업데이트
 * updateDate를 현재 Timestamp로 갱신한다.
 */
export function updateWordbookTimeState(uid, leitnerBoxId, callback) {
  const url =
    DB_SERVER_URL +
    "/wordbook/state/update/date?uid=" +
    uid +
    "&leitnerBoxId=" +
    leitnerBoxId;

  if (process.env.NODE_ENV !== "production")
    console.log("updateWordbookTimeState url:" + url);
  axios
    .post(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("error:" + err));
}

/**********************************************************************************************************/
/** LeitnerBox */

/**
 * 사용자의 LeitnerBox 생성
 */
export function createLeinterbox(wordbookId, count, uid, callback) {
  const url =
    DB_SERVER_URL +
    "/leitner/create?wordbookId=" +
    wordbookId +
    "&count=" +
    count +
    "&uid=" +
    uid;

  if (process.env.NODE_ENV !== "production")
    console.log("createLeinterbox url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("createLeinterbox error:" + err));
}

/**
 * 사용자의 LeitnerBox 목록 요청 (by Wordbook ID)
 */
export function getLeitnerboxInfoByWordbookId(wordbookId, uid, callback) {
  const url =
    DB_SERVER_URL + "/leitner/list?uid=" + uid + "&wordbookId=" + wordbookId;

  if (process.env.NODE_ENV !== "production")
    console.log("getLeitnerboxInfoByWordbookId url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getLeitnerboxInfoByWordbookId error:" + err));
}

/**
 * 사용자의의 LeitnerBox 요청 (by Leitnerbox ID)
 */
export function getLeitnerboxInfoByLeitnerBoxId(leitnerBoxId, uid, callback) {
  const url =
    DB_SERVER_URL + "/leitner?leitnerBoxId=" + leitnerBoxId + "&uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getLeitnerboxInfoByLeitnerBoxId url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) =>
      console.log("getLeitnerboxInfoByLeitnerBoxId error:" + err)
    );
}

/**
 * 사용자의 LeitnerBox의 단어리스트를 요청 (by LeitnerBox ID)
 *
 * 사용자의 LeitnerBox의 단어 리스트를 요청한다. needExtra의 값(true 또는 false)에 따라 추가 정보(hasRelation, csatYear)가 포함되어 리턴된다.
 */
export function getLeitnerboxWords(leitnerBoxId, uid, needExtra, callback) {
  const url =
    DB_SERVER_URL +
    "/leitner/items?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid +
    "&needExtra=" +
    needExtra;

  if (process.env.NODE_ENV !== "production")
    console.log("getLeitnerboxWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getLeitnerboxWords error:" + err));
}

/**
 * 사용자의 LeitnerBox 업데이트
 * 특정 사용자의 LeitnerBox를 업데이트 한다.
 *
 * API호출 시 lastModified는 현재 Timestamp로 갱신한다.
 * LeitnerBox의 remindTimestamp는 lastModified에 remainingTime를 더하여 갱신한다.
 */
export function updateLeitnerboxInfo(
  leitnerBoxId,
  uid,
  level,
  remainingTime,
  callback
) {
  const url =
    DB_SERVER_URL +
    "/leitner/update?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid +
    "&level=" +
    level +
    "&remainingTime=" +
    remainingTime;

  if (process.env.NODE_ENV !== "production")
    console.log("updateLeitnerboxInfo url:" + url);
  axios
    .post(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("updateLeitnerboxInfo error:" + err));
}

/**
 * LeitnerBox 삭제 (by Wordbook ID)
 * 특정 유저의 LeitnerBox들을 삭제한다.
 *
 * 특정 유저의 LeitnerBox들 중 Wordbook ID가 일치하는 box들은 모두 삭제된다.
 * 연관된 테이블(LeitnerBoxWordItemInfo, WordbookState, MemorizeLevelInfo, MemorizeWordInfo)의 자료들도 같이 삭제된다.
 */
export function deleteLeitnerboxs(wordbookId, uid, callback) {
  const url =
    DB_SERVER_URL + "/leitner/delete?uid=" + uid + "&wordbookId=" + wordbookId;

  if (process.env.NODE_ENV !== "production")
    console.log("deleteLeitnerboxs url:" + url);
  axios
    .delete(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("deleteLeitnerboxs error:" + err));
}

/**
 * 최신 암기 도래한 LeitnerBox 정보
 * 최신 암기 도래한 LeitnerBox의 Wordbook ID를 가져온다.
 */
export function getLatestWordbookId(uid, callback) {
  const url = DB_SERVER_URL + "/leitner/latest?uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getLatestWordbookId url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => {
      console.log("getLatestWordbookId error:" + err);
      callback(null);
    });
}

/**
 * LeitnerBox 상태 확인
 * 특정 Wordbook 내에 더 암기할 LeitnerBox가 있는지 상태를 확인하는 API이다.
 *
 * 암기할 LeitnerBox가 있다면 true, 없다면 false를 리턴한다.
 */
export function isExistMemorizable(uid, wordbookId, callback) {
  const url =
    DB_SERVER_URL + "/leitner/state?uid=" + uid + "&wordbookId=" + wordbookId;

  if (process.env.NODE_ENV !== "production")
    console.log("isExistMemorizable url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("isExistMemorizable error:" + err));
}
/**********************************************************************************************************/
/** Quiz */

/**
 * 오답용 단어 또는 뜻 요청
 */
export function getWrongAnswer(uniqueId, type, callback) {
  const url =
    DB_SERVER_URL + "/quiz/wrongAnswers?uniqueId=" + uniqueId + "&type=" + type;

  if (process.env.NODE_ENV !== "production")
    console.log("getWrongAnswer url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getWrongAnswer error:" + err));
}

/**
 * Summary 추가
 */
export function addQuizSummary(
  quizDate,
  correctCount,
  totalCount,
  uid,
  callback
) {
  const url = DB_SERVER_URL + "/quiz/summary/update";

  if (process.env.NODE_ENV !== "production")
    console.log("addQuizSummary url:" + url);
  var data = {
    quizDate: quizDate,
    correctCount: correctCount,
    totalCount: totalCount,
    uid: uid + "",
  };

  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("addQuizSummary error:" + err));
}

/**
 * Summary 목록 요청
 */
export function getQuizSummary(uid, callback) {
  const url = DB_SERVER_URL + "/quiz/summary/list?uid=" + uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getQuizSummary url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => {
      console.log("getQuizSummary error:" + err);
      if (callback) callback(null);
    });
}

/**
 * QuizHistoryWordInfo 추가, 업데이트
 *
 * QuizHistoryWordInfo의 정보를 추가하거나 업데이트하는 API이다.
 */
export function addQuizHistoryWord(quizDate, uid, data, callback) {
  const url =
    DB_SERVER_URL + "/quiz/history/update?uid=" + uid + "&quizDate=" + quizDate;

  if (process.env.NODE_ENV !== "production")
    console.log("addQuizHistoryWord url:" + url);

  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("addQuizHistoryWord error:" + err));
}

/**
 * QuizHistoryWordInfo 목록 요청 (by UID and Quiz Date)
 * uid와 quizDate가 일치하는 QuizHistoryWordInfo 목록을 요청하는 API이다.
 */
export function getQuizHistoryWords(uid, date, callback) {
  const url = DB_SERVER_URL + "/quiz/history?uid=" + uid + "&date=" + date;

  if (process.env.NODE_ENV !== "production")
    console.log("getQuizHistoryWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getQuizHistoryWords error:" + err));
}

/**********************************************************************************************************/
/** Memorize */

/**
 * MemorizeLevelInfo 추가, 갱신
 */
export function updateMemorizeLevel(
  leitnerBoxId,
  level,
  wholeTotalCount,
  wholeKnownCount,
  uid,
  callback
) {
  const url = DB_SERVER_URL + "/memorize/level/update";

  if (process.env.NODE_ENV !== "production")
    console.log("updateMemorizeLevel url:" + url);

  const data = {
    leitnerBoxId: Number(leitnerBoxId),
    level: level,
    wholeCount: wholeTotalCount,
    knownCount: wholeKnownCount,
    uid: uid + "",
  };

  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("updateMemorizeLevel error:" + err));
}
/**
 * MemorizeLevelInfo 삭제 요청
 */
export function deleteMemorizeLevel(leitnerBoxId, uid, callback) {
  const url =
    DB_SERVER_URL +
    "/memorize/level/delete?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid;

  if (process.env.NODE_ENV !== "production")
    console.log("deleteMemorizeLevel url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("deleteMemorizeLevel error:" + err));
}

/**
 * MemorizeLevelInfo 목록 요청 (by LeitnerBox ID)
 */
export function getMemorizeLevel(leitnerBoxId, uid, callback) {
  const url =
    DB_SERVER_URL +
    "/memorize/level?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getMemorizeLevel url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("getMemorizeLevel error:" + err));
}

/**
 * MemorizeWordInfo 추가, 업데이트
 *
 * uniqueId와 uid가 일치하는 데이터가 있으면 업데이트하며 일치하는 데이터가 없으면 추가하는 API이다.
 *     var json = [{
 *         "uniqueId": 268,
 *         "unKnownCount": 10
 *     }, ... ];
 */
export function updateMemorizeWord(leitnerBoxId, uid, data, callback) {
  const url =
    DB_SERVER_URL +
    "/memorize/word/update?uid=" +
    uid +
    "&leitnerBoxId=" +
    leitnerBoxId;

  if (process.env.NODE_ENV !== "production")
    console.log("updateMemorizeWord url:" + url);

  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("updateMemorizeWord error:" + err));
}

/**
 * MemorizeWordInfo 목록 요청 (by LeitnerBox ID)
 */
export function getMemorizeWords(leitnerBoxId, uid, callback) {
  const url =
    DB_SERVER_URL +
    "/memorize/word/list?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid;

  if (process.env.NODE_ENV !== "production")
    console.log("getMemorizeWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("error:" + err));
}

/**
 * MemorizeWordInfo 삭제 요청
 */
export function deleteMemorizeWords(leitnerBoxId, uid, callback) {
  const url =
    DB_SERVER_URL +
    "/memorize/word/delete?leitnerBoxId=" +
    leitnerBoxId +
    "&uid=" +
    uid;

  if (process.env.NODE_ENV !== "production")
    console.log("deletMemorizeWords url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("error:" + err));
}

export function getNaverLoginId(token, callback) {
  const url = DB_SERVER_URL + "/user/profile?token=" + token;

  if (process.env.NODE_ENV !== "production")
    console.log("getNaverLoginId url:" + url);
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) throw new Error(JSON.stringify(response.data));
      else if (callback) callback(response.data);
    })
    .catch((err) => console.log("error:" + err));
}
