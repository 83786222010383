let availableSessionStorage = null;

function sessionAvailable() {
  if (availableSessionStorage === null) {
    availableSessionStorage = false;
    var storage;
    try {
      storage = window["sessionStorage"];
      var x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      availableSessionStorage = true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // Firefox를 제외한 모든 브라우저
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // 코드가 존재하지 않을 수도 있기 떄문에 이름 필드도 확인합니다.
          // Firefox를 제외한 모든 브라우저
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
        storage &&
        storage.length !== 0
      );
    }
  }
  return availableSessionStorage;
}

export function getToken() {
  if (!sessionAvailable()) return null;
  return sessionStorage.getItem("token");
}

export function getUserType() {
  if (!sessionAvailable()) return null;
  return sessionStorage.getItem("userType");
}

export function setTokenAndUserType(userType, token) {
  if (!sessionAvailable()) return;
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("userType", userType);
}

export function setUserType(userType) {
  if (!sessionAvailable()) return;
  sessionStorage.setItem("userType", userType);
}

export function getId() {
  if (!sessionAvailable()) return null;
  return sessionStorage.getItem("id");
}

export function setUserInfoSession(id, email, userType) {
  if (!sessionAvailable()) return;
  sessionStorage.setItem("id", id);
  sessionStorage.setItem("email", email);
  sessionStorage.setItem("userType", userType);
}

export function getEmail() {
  if (!sessionAvailable()) return null;
  return sessionStorage.getItem("email");
}

export function removeSessions() {
  if (!sessionAvailable()) return;
  sessionStorage.clear();
}
